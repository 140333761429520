const VAT = [
  // Order is important! Biggest year first.
  { since: 2024, rate: 8.1 },
  { since: 2018, rate: 7.7 },
];

const CO2_TAX = [
  // Order is important! Biggest year first.
  { since: 2024, rate: 0.02156 },
  { since: 2023, rate: 0.02178 },
  { since: 2022, rate: 0.02169 },
];

// Allow to override the current year through a query param in the URL
// so the new prices can be previewed in advance
const currentUrl = new URL(window.location);
const forcedYear = currentUrl.searchParams.get('year');

function getRateForCurrentYear(values) {
  const currentYear = forcedYear ?? new Date().getFullYear();
  const tax = values.find(({ since }) => currentYear >= since);
  return (tax ?? values[0]).rate;
}

export function getVAT() {
  return getRateForCurrentYear(VAT);
}

export function getCO2Tax() {
  return getRateForCurrentYear(CO2_TAX);
}

import { formatMoney, formatNumber as baseFormatNumber } from 'accounting-js';
import { getVAT } from '@/config';

/**
 * Browsers’ implementation is damn gross
 * Some properties can be defined but always equal zero, thus requiring the Math.max
 */
function getDocumentScrollTop() {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop,
    0
  );
}

/**
 * Return given element scrollTop value
 */
export function scrollTop(element) {
  if (element === window || element === document) {
    return getDocumentScrollTop();
  } else {
    return element.scrollTop;
  }
}

/**
 * A setTimeout you can pause & resume
 */
export function Timer(callback, delay) {
  let remaining = delay;
  let id;
  let start;

  this.pause = function () {
    clearTimeout(id);
    remaining -= new Date() - start;
  };

  this.resume = function () {
    start = new Date();
    clearTimeout(id);
    id = setTimeout(callback, remaining);
  };

  this.resume();
}

export function formatCurrency(value) {
  return formatMoney(value, {
    symbol: 'CHF',
    precision: 0,
    thousand: '’',
    format: '%s %v.—',
  });
}

export function formatNumber(value) {
  return baseFormatNumber(value, { thousand: "'", precision: 0 });
}

export function addVAT(value) {
  return (value += (value * getVAT()) / 100);
}

export function formatList(list) {
  if (window.Intl?.ListFormat) {
    return new Intl.ListFormat(window.ENP.CONFIG.language, {
      style: 'long',
      type: 'conjunction',
    }).format(list);
  } else {
    return list.join(', ');
  }
}
